<template>
	<div>
		<scabe class="mx-2 my-3 border-b border-black" :name="me.name" />
		<div class="py-2 border-b border-black w-3/5 mx-2 font-bold">
			<p class="mt-2">Pedidos Completados</p>
		</div>
		<div class="mt-3 mx-2">
			<table class="table-auto">
				<thead>
					<tr>
						<th class="border border-black p-1">Orden de compra</th>
						<th class="border border-black p-1">Estado</th>
						<th class="border border-black p-1">Hora de entrega</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(pedido, k) in PedidoVendedorCompletado" :key="k">
						<td class="border border-black p-1 text-center">
							<router-link
								class="text-blue-500 underline"
								:to="{
									name: 'Vpedido',
									params: {
										id: pedido.id,
										name: me.name,
										estatus: pedido.estatus
									}
								}"
								>{{ pedido.id.padStart(4, 0) }}</router-link
							>
						</td>
						<td class="border border-black p-1 text-center">
							{{ pedido.estatus }}
						</td>
						<td class="border border-black p-1 text-center">
							{{ pedido.tiempo_estimado }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import scabe from "@/components/subCabeceraV.vue";
import gql from "graphql-tag";
export default {
	components: {
		scabe
	},
	data() {
		return {
			me: [],
			Vid: ""
		};
	},
	mounted() {
		this.$store.state.isLoading = true;
		this.$apollo
			.query({
				query: gql`
					query me {
						me {
							name
							id
							vendedores {
								id
								disponible
							}
						}
					}
				`
			})
			.then(data => {
				this.$store.state.isLoading = false;
				this.me = data.data.me;
				this.Vid = data.data.me.vendedores[0].id;
			})
			.catch(error => {
				this.$store.state.isLoading = false;
				console.log(error);
			});
	},

	apollo: {
		PedidoVendedorCompletado: {
			query: gql`
				query PedidoVendedorCompletado($id: ID!) {
					PedidoVendedorCompletado(vendedor_id: $id) {
						id
						estatus
						vendedor_id
						tiempo_estimado
						fecha_entrega
					}
				}
			`,
			variables() {
				return {
					id: this.Vid
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.PedidoVendedorCompletado,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	}
};
</script>
