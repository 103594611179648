var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('scabe',{staticClass:"mx-2 my-3 border-b border-black",attrs:{"name":_vm.me.name}}),_vm._m(0),_c('div',{staticClass:"mt-3 mx-2"},[_c('table',{staticClass:"table-auto"},[_vm._m(1),_c('tbody',_vm._l((_vm.PedidoVendedorCompletado),function(pedido,k){return _c('tr',{key:k},[_c('td',{staticClass:"border border-black p-1 text-center"},[_c('router-link',{staticClass:"text-blue-500 underline",attrs:{"to":{
								name: 'Vpedido',
								params: {
									id: pedido.id,
									name: _vm.me.name,
									estatus: pedido.estatus
								}
							}}},[_vm._v(_vm._s(pedido.id.padStart(4, 0)))])],1),_c('td',{staticClass:"border border-black p-1 text-center"},[_vm._v(" "+_vm._s(pedido.estatus)+" ")]),_c('td',{staticClass:"border border-black p-1 text-center"},[_vm._v(" "+_vm._s(pedido.tiempo_estimado)+" ")])])}),0)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 border-b border-black w-3/5 mx-2 font-bold"},[_c('p',{staticClass:"mt-2"},[_vm._v("Pedidos Completados")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"border border-black p-1"},[_vm._v("Orden de compra")]),_c('th',{staticClass:"border border-black p-1"},[_vm._v("Estado")]),_c('th',{staticClass:"border border-black p-1"},[_vm._v("Hora de entrega")])])])}]

export { render, staticRenderFns }